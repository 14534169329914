
import { Component, Vue } from 'vue-property-decorator';

import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { User } from '../../entities/user.entity';
import userService from '../../services/user.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class UserListComponent extends Vue {
  private users: User[] = [];

  private itemToDelete: User = new User();

  private get headers(): any {
    const headers = [
      { text: 'Nome', value: 'name', width: '30%' },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Perfil',
        value: 'roleName',
      },
      {
        text: 'Ativo',
        value: 'activeText',
      },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];
    if (this.$permissionAccess.group.isSuperAdmin) {
      headers.splice(2, 1, {
        text: 'Empresa',
        value: 'company_name',
      });
    }
    return headers;
  }

  private goToFormEdit(user: User) {
    this.$router.push({
      name: 'user-edit',
      params: {
        id: user.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'user-create',
    });
  }

  private async getUsers() {
    const users = await userService.getAllUsers({});
    this.users = users.map((user: User) => new User(user));
  }

  public async backPagination() {
    if (!userService.simplePaginationService.prevLink) return;
    const users = await userService.simplePaginationService.prev();
    this.users = users.map((user: User) => new User(user));
  }

  public async nextPagination() {
    if (!userService.simplePaginationService.nextLink) return;
    const users = await userService.simplePaginationService.next();
    this.users = users.map((user: User) => new User(user));
  }

  public async goToFirst() {
    if (!userService.simplePaginationService.firstLink) return;
    const users = await userService.simplePaginationService.goToFirst();
    this.users = users.map((user: User) => new User(user));
  }

  private async created() {
    this.getUsers();
  }
}
